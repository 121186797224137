<template>
  <div class="whats-happening-container">
    <DestinationWhatsHappening
      v-if="entities.artFairs.data.length"
      :destination="destination"
      :has-more-data="entities.artFairs.hasMorePages"
      :is-mobile-screen="isMobileScreen"
      route-all-name="art-fairs"
      route-name="art-fair"
      title="art fairs"
      :type="whatsHappeningTypes.artFair"
      :whats-happening-data="entities.artFairs.data"
    />
    <DestinationWhatsHappening
      v-if="entities.events.data.length"
      :destination="destination"
      :has-more-data="entities.events.hasMorePages"
      :is-mobile-screen="isMobileScreen"
      route-all-name="events"
      route-name="event"
      title="events"
      :type="whatsHappeningTypes.event"
      :whats-happening-data="entities.events.data"
    />
    <DestinationWhatsHappening
      v-if="entities.museumExhibitions.data.length"
      :destination="destination"
      :has-more-data="entities.museumExhibitions.hasMorePages"
      :is-mobile-screen="isMobileScreen"
      route-all-name="exhibitions"
      route-name="exhibition"
      title="Museum exhibitions"
      :type="whatsHappeningTypes.exhibitionFromGallery"
      :whats-happening-data="entities.museumExhibitions.data"
    />
    <DestinationWhatsHappening
      v-if="entities.galleryExhibitions.data.length"
      :destination="destination"
      :has-more-data="entities.galleryExhibitions.hasMorePages"
      :is-mobile-screen="isMobileScreen"
      route-all-name="exhibitions"
      route-name="exhibition"
      title="Gallery shows"
      :type="whatsHappeningTypes.exhibitionFromMuseum"
      :whats-happening-data="entities.galleryExhibitions.data"
    />

    <DestinationTabNoResults :is-loading="isDataLoading" :is-no-results="emptyData" />
  </div>
</template>

<script>
import WHATS_HAPPENING_TYPES from '@/models/whatsHappeningTypes';

import artFairsQuery from '@/graphql/artFair/ArtFairsHappeningInDestination.query.gql';
import eventsQuery from '@/graphql/event/EventsHappeningInDestination.query.gql';
import museumExhibitionsQuery from '@/graphql/exhibition/ExhibitionsByMuseumHappeningInDestination.query.gql';
import galleryExhibitionsQuery from '@/graphql/exhibition/ExhibitionsByGalleryHappeningInDestination.query.gql';

import DestinationWhatsHappening from '@/components/destination/DestinationWhatsHappening';
import DestinationTabNoResults from '@/components/DestinationTabNoResults.vue';

const DATA_PER_PAGE = 6;

export default {
  name: 'DestinationWhatsHappeningTab',
  components: {
    DestinationTabNoResults,
    DestinationWhatsHappening,
  },
  props: {
    destination: {
      type: Object,
      required: true,
    },
    isMobileScreen: {
      type: Boolean,
      default: false,
    },
    isMuseumMemberRole: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      entities: {
        artFairs: { data: [], hasMorePages: false, isLoading: true, page: 1 },
        events: { data: [], hasMorePages: false, isLoading: true, page: 1 },
        museumExhibitions: { data: [], hasMorePages: false, isLoading: true, page: 1 },
        galleryExhibitions: { data: [], hasMorePages: false, isLoading: true, page: 1 },
      },
    };
  },
  apollo: {
    artFairs: {
      query: artFairsQuery,
      variables() {
        return {
          destinationId: this.destination.id,
          limit: DATA_PER_PAGE,
          page: this.entities.artFairs.page,
        };
      },
      result({ data }) {
        this.entities.artFairs.data.push(...data.artFairs.data);
        this.entities.artFairs.hasMorePages = data.artFairs.hasMorePages;
        this.entities.artFairs.isLoading = false;
      },
    },
    events: {
      query: eventsQuery,
      variables() {
        return {
          destinationId: this.destination.id,
          limit: DATA_PER_PAGE,
          page: this.entities.events.page,
        };
      },
      result({ data }) {
        this.entities.events.data.push(...data.events.data);
        this.entities.events.hasMorePages = data.events.hasMorePages;
        this.entities.events.isLoading = false;
      },
    },
    museumExhibitions: {
      query: museumExhibitionsQuery,
      variables() {
        return {
          destinationId: this.destination.id,
          limit: DATA_PER_PAGE,
          page: this.entities.museumExhibitions.page,
        };
      },
      result({ data }) {
        this.entities.museumExhibitions.data.push(...data.museumExhibitions.data);
        this.entities.museumExhibitions.hasMorePages = data.museumExhibitions.hasMorePages;
        this.entities.museumExhibitions.isLoading = false;
      },
    },
    galleryExhibitions: {
      query: galleryExhibitionsQuery,
      variables() {
        return {
          destinationId: this.destination.id,
          limit: DATA_PER_PAGE,
          page: this.entities.galleryExhibitions.page,
        };
      },
      result({ data }) {
        this.entities.galleryExhibitions.data.push(...data.galleryExhibitions.data);
        this.entities.galleryExhibitions.hasMorePages = data.galleryExhibitions.hasMorePages;
        this.entities.galleryExhibitions.isLoading = false;
      },
    },
  },
  computed: {
    emptyData() {
      return (
        !this.entities.artFairs.data.length &&
        !this.entities.events.data.length &&
        !this.entities.museumExhibitions.data.length &&
        !this.entities.galleryExhibitions.data.length
      );
    },
    isDataLoading() {
      return (
        this.entities.artFairs.isLoading ||
        this.entities.events.isLoading ||
        this.entities.museumExhibitions.isLoading ||
        this.entities.galleryExhibitions.isLoading
      );
    },
    whatsHappeningTypes() {
      return WHATS_HAPPENING_TYPES;
    },
  },
  created() {
    this.$root.$on('loadMore', (type) => {
      switch (type) {
        case WHATS_HAPPENING_TYPES.artFair:
          this.entities.artFairs.page++;
          break;
        case WHATS_HAPPENING_TYPES.event:
          this.entities.events.page++;
          break;
        case WHATS_HAPPENING_TYPES.exhibitionFromMuseum:
          this.entities.museumExhibitions.page++;
          break;
        case WHATS_HAPPENING_TYPES.exhibitionFromGallery:
          this.entities.galleryExhibitions.page++;
          break;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off('loadMore');
  },
};
</script>

<style lang="scss" scoped>
.whats-happening-container {
  padding-bottom: 40px;

  @media (min-width: 768px) {
    margin-top: 60px;
  }
}
</style>
